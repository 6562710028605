<script>
import { FormWizard, TabContent } from "vue-form-wizard";
// import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Form wizard component
 */
export default {
  page: {
    title: "Form Wizard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {FormWizard, TabContent },
  data() {
    return {
      title: "Form Wizard",
      items: [
        {
          text: "Forms",
          href: "/",
        },
        {
          text: "Form Wizard",
          active: true,
        },
      ],
      HBCategories:[
        {name:'Standard',type:'Std'},
        {name:'Intermediar',type:'Itd'},
        {name:'Luxe - VIP',type:'VIP'},
      ],
      Hebergement:[],
      Restauration:[],
      Bureaux:[],
      Magasins:[],
      Loisirs:[],
      Espaces:[],
      room_type:{
        name:'',
        type:'',
        description:''
      },
      resto_type:{
        name:'',
        type:'',
        description:''
      },
      bur_type:{
        name:'',
        type:'',
        description:''
      }
    };
  },
  methods:{
    AddTypeH(item,HB){
      console.log(HB)
      this.Hebergement.push({category:HB,name:item.name, type:item.type, description:item.description});
      this.resetInputs()
    },
    removeH(i){
        this.Hebergement.splice(i,1);
    },
    AddTypeR(item){
      this.Restauration.push({name:item.name, type:item.type, description:item.description});
      this.resetInputs()
    },
    removeR(i){
        this.Restauration.splice(i,1);
    },
    AddTypeB(item){
      this.Bureaux.push({name:item.name, type:item.type, description:item.description});
      this.resetInputs()
    },
    removeB(i){
        this.Bureaux.splice(i,1);
    },
    AddTypeM(item){
      this.Magasins.push({name:item.name, type:item.type, description:item.description});
      this.resetInputs()
    },
    removeM(i){
        this.Magasins.splice(i,1);
    },
    AddTypeL(item){
      this.Loisirs.push({name:item.name, type:item.type, description:item.description});
      this.resetInputs()
    },
    removeL(i){
        this.Loisirs.splice(i,1);
    },
    AddTypeV(item){
      this.Espaces.push({name:item.name, type:item.type, description:item.description});
      this.resetInputs()
    },
    removeV(i){
        this.Espaces.splice(i,1);
    },

    resetInputs(){
      this.selectedHB = "";
      this.room_type.name="";
      this.room_type.type="";
      this.room_type.description="";

      this.resto_type.name="";
      this.resto_type.type="";
      this.resto_type.description="";
      
      this.bur_type.name="";
      this.bur_type.type="";
      this.bur_type.description="";
    }
  }
};
</script>

<template>
    <div class="row">
      <!-- end col -->
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <form-wizard shape="tab" color="#556ee6">
              <!-- Hebergmeent -->
              <tab-content icon="mdi mdi-warehouse">
                <div class="row">
                  <div class="col-lg-6">
                      <div class="row">
                          <label for="">Categories d'Hebergement</label>
                          <select class="form-control" v-model="selectedHB">
                              <option v-for="Cat in HBCategories" :key="Cat.type" :value="Cat.type"> {{Cat.name}} </option>
                          </select>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label for="">Nom *</label>
                          <input type="text" class="form-control" ref="name" v-model="room_type.name">
                        </div>
                        <div class="col-md-6">
                          <label for="">Type *</label>
                          <input type="text" class="form-control" ref="type" v-model="room_type.type">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                              <div class="form-group">
                                <label for="formrow-inputCity">Description *</label>
                                <textarea
                                  v-model="room_type.description"
                                  class="form-control"
                                  cols="30"
                                  rows="1" 
                                  required
                                  ref="description"
                                ></textarea>
                              </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col text-right">
                          <b-button pill variant="primary" @click="AddTypeH(room_type,selectedHB)">Ajouter</b-button>
                        </div>
                      </div>
                  </div>
                  <div class="col-lg-6">
                    <h4>Liste Des Types d'Hebergement</h4>
                      <b-list-group>
                        <b-list-group-item v-for="(type,i) in Hebergement" :key="type.type">
                          <div class="row">
                            <div class="col mt-1">{{type.category}}</div>
                            <div class="col mt-1">{{type.name}}</div>
                            <div class="col mt-1">{{type.type}}</div>
                            <div class="col text-right"><a @click="removeH(i)" class="text-danger"> <i class="mdi mdi-close font-size-18"></i> </a></div>
                          </div>
                        </b-list-group-item>
                      </b-list-group>
                  </div>
                </div>
                <!-- end row -->
              </tab-content>
              <!-- Restauration -->
              <tab-content icon="mdi mdi-food">
                <div class="row">
                  <div class="col-lg-6">
                      <div class="row">
                        <div class="col-md-6">
                          <label for="">Nom *</label>
                          <input type="text" class="form-control" ref="name" v-model="resto_type.name">
                        </div>
                        <div class="col-md-6">
                          <label for="">Type *</label>
                          <input type="text" class="form-control" ref="type" v-model="resto_type.type">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                              <div class="form-group">
                                <label for="formrow-inputCity">Description *</label>
                                <textarea
                                  v-model="resto_type.description"
                                  class="form-control"
                                  cols="30"
                                  rows="1" 
                                  required
                                  ref="description"
                                ></textarea>
                              </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col text-right">
                          <b-button pill variant="primary" @click="AddTypeR(resto_type)">Ajouter</b-button>
                        </div>
                      </div>
                  </div>
                  <div class="col-lg-6">
                    <h4>Liste Des Types Restauration</h4>
                      <b-list-group>
                        <b-list-group-item v-for="(typeR,index) in Restauration" :key="typeR.type"><div class="row">
                            <div class="col mt-1">{{typeR.name}}</div>
                            <div class="col mt-1">{{typeR.type}}</div>
                            <div class="col text-right"><a @click="removeR(index)" class="text-danger"> <i class="mdi mdi-close font-size-18"></i> </a></div>
                          </div></b-list-group-item>
                      </b-list-group>
                  </div>
                </div>
              </tab-content>
              <!-- Bureaux -->
              <tab-content icon="fas fa-building">
                <div class="row">
                  <div class="col-lg-6">
                      <div class="row">
                        <div class="col-md-6">
                          <label for="">Nom *</label>
                          <input type="text" class="form-control" ref="name" v-model="bur_type.name">
                        </div>
                        <div class="col-md-6">
                          <label for="">Type *</label>
                          <input type="text" class="form-control" ref="type" v-model="bur_type.type">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                              <div class="form-group">
                                <label for="formrow-inputCity">Description *</label>
                                <textarea
                                  v-model="bur_type.description"
                                  class="form-control"
                                  cols="30"
                                  rows="1" 
                                  required
                                  ref="description"
                                ></textarea>
                              </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col text-right">
                          <b-button pill variant="primary" @click="AddTypeR(bur_type)">Ajouter</b-button>
                        </div>
                      </div>
                  </div>
                  <div class="col-lg-6">
                    <h4>Liste Des Types Bureaux et Locaux</h4>
                      <b-list-group>
                        <b-list-group-item v-for="(typeB,index) in Bureaux" :key="typeB.type"><div class="row">
                            <div class="col mt-1">{{typeB.name}}</div>
                            <div class="col mt-1">{{typeB.type}}</div>
                            <div class="col text-right"><a @click="removeB(index)" class="text-danger"> <i class="mdi mdi-close font-size-18"></i> </a></div>
                          </div></b-list-group-item>
                      </b-list-group>
                  </div>
                </div>
              </tab-content>
              <!-- Magasins -->
              <tab-content icon="mdi mdi-store">
                <div class="row">
                  <div class="col-lg-6">
                      <div class="row">
                        <div class="col-md-6">
                          <label for="">Nom *</label>
                          <input type="text" class="form-control" ref="name" v-model="bur_type.name">
                        </div>
                        <div class="col-md-6">
                          <label for="">Type *</label>
                          <input type="text" class="form-control" ref="type" v-model="bur_type.type">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                              <div class="form-group">
                                <label for="formrow-inputCity">Description *</label>
                                <textarea
                                  v-model="bur_type.description"
                                  class="form-control"
                                  cols="30"
                                  rows="1" 
                                  required
                                  ref="description"
                                ></textarea>
                              </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col text-right">
                          <b-button pill variant="primary" @click="AddTypeR(bur_type)">Ajouter</b-button>
                        </div>
                      </div>
                  </div>
                  <div class="col-lg-6">
                    <h4>Liste Des Types Magasins et Entrepôts</h4>
                      <b-list-group>
                        <b-list-group-item v-for="(typeM,index) in Magasins" :key="typeM.type"><div class="row">
                            <div class="col mt-1">{{typeM.name}}</div>
                            <div class="col mt-1">{{typeM.type}}</div>
                            <div class="col text-right"><a @click="removeM(index)" class="text-danger"> <i class="mdi mdi-close font-size-18"></i> </a></div>
                          </div></b-list-group-item>
                      </b-list-group>
                  </div>
                </div>
              </tab-content>
              <!-- Loisirs -->
              <tab-content icon="fas fa-running">
                <div class="row">
                  <div class="col-lg-6">
                      <div class="row">
                        <div class="col-md-6">
                          <label for="">Nom *</label>
                          <input type="text" class="form-control" ref="name" v-model="bur_type.name">
                        </div>
                        <div class="col-md-6">
                          <label for="">Type *</label>
                          <input type="text" class="form-control" ref="type" v-model="bur_type.type">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                              <div class="form-group">
                                <label for="formrow-inputCity">Description *</label>
                                <textarea
                                  v-model="bur_type.description"
                                  class="form-control"
                                  cols="30"
                                  rows="1" 
                                  required
                                  ref="description"
                                ></textarea>
                              </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col text-right">
                          <b-button pill variant="primary" @click="AddTypeL(bur_type)">Ajouter</b-button>
                        </div>
                      </div>
                  </div>
                  <div class="col-lg-6">
                    <h4>Liste Des Types Sport et Loisirs</h4>
                      <b-list-group>
                        <b-list-group-item v-for="(typeL,index) in Loisirs" :key="typeL.type"><div class="row">
                            <div class="col mt-1">{{typeL.name}}</div>
                            <div class="col mt-1">{{typeL.type}}</div>
                            <div class="col text-right"><a @click="removeL(index)" class="text-danger"> <i class="mdi mdi-close font-size-18"></i> </a></div>
                          </div></b-list-group-item>
                      </b-list-group>
                  </div>
                </div>
              </tab-content>
              <!-- Espace Vert -->
              <tab-content icon="mdi mdi-pine-tree">
                <div class="row">
                  <div class="col-lg-6">
                      <div class="row">
                        <div class="col-md-6">
                          <label for="">Nom *</label>
                          <input type="text" class="form-control" ref="name" v-model="bur_type.name">
                        </div>
                        <div class="col-md-6">
                          <label for="">Type *</label>
                          <input type="text" class="form-control" ref="type" v-model="bur_type.type">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                              <div class="form-group">
                                <label for="formrow-inputCity">Description *</label>
                                <textarea
                                  v-model="bur_type.description"
                                  class="form-control"
                                  cols="30"
                                  rows="1" 
                                  required
                                  ref="description"
                                ></textarea>
                              </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col text-right">
                          <b-button pill variant="primary" @click="AddTypeV(bur_type)">Ajouter</b-button>
                        </div>
                      </div>
                  </div>
                  <div class="col-lg-6">
                    <h4>Liste Des Types d'Espaces Vert</h4>
                      <b-list-group>
                        <b-list-group-item v-for="(typeV,index) in Espaces" :key="typeV.type"><div class="row">
                            <div class="col mt-1">{{typeV.name}}</div>
                            <div class="col mt-1">{{typeV.type}}</div>
                            <div class="col text-right"><a @click="removeV(index)" class="text-danger"> <i class="mdi mdi-close font-size-18"></i> </a></div>
                          </div></b-list-group-item>
                      </b-list-group>
                  </div>
                </div>
              </tab-content>
              <!-- <tab-content icon="mdi mdi-checkbox-marked-circle-outline">
                <div class="row">
                  <div class="col-12">
                    <div class="text-center">
                      <h2 class="mt-0">
                        <i class="mdi mdi-check-all"></i>
                      </h2>
                      <h3 class="mt-0">Thank you !</h3>

                      <p class="w-75 mb-2 mx-auto">
                        Quisque nec turpis at urna dictum luctus. Suspendisse
                        convallis dignissim eros at volutpat. In egestas mattis
                        dui. Aliquam mattis dictum aliquet.
                      </p>

                      <div class="mb-3">
                        <b-form-checkbox
                          class="form-check"
                          id="customCheck1"
                          name="checkbox-1"
                          value="accepted"
                          unchecked-value="not_accepted"
                        >
                          I agree with the Terms and Conditions
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                  
                </div>
                
              </tab-content> -->
            </form-wizard>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
</template>



