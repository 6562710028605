<script>
// import Layout from "../../../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { FormWizard, TabContent } from "vue-form-wizard";
import Swal from "sweetalert2";
// import { required } from "vuelidate/lib/validators";
import { fetchSettings } from "../api";
import Infrastructure from './infrastructure.vue';
import Multiselect from 'vue-multiselect'

export default {
    page: {
        title: "Ajout Nouvelle Base de Vie",
        meta: [{ name: "description", content: appConfig.description }],
    },
    import : { fetchSettings },
    components: { /*Layout, PageHeader,*/ FormWizard,TabContent,Infrastructure,Multiselect},
    data() {
      return {
      tableData: [],
      title: "New BaseLife",
      info: {
        name:'',
        email:'',
        phone:'',
        state:'',
        address:'',
        rooms:'',
        offices:'',
        restaurants:'',
        kitchens:''
      },
      items: [
        {
          text: "Paramètres",
          href: "javascript:void(0)",
        },
        {
          text: "Lifebase",
          href: "javascript:void(0)",
        },
        {
          text: "New Base",
          active: true,
        },
      ],
      SelectedCategory:undefined,
      SelectedPoste:undefined,
      DiffusedRadio:undefined,
      Categories:[
          {text:"Management", value:'Mgt'},
          {text:"Administration", value:'Adt'},
          {text:"Supervision", value:'Sv'},
          {text:"Autres Staff", value:'AS'},
      ],
      Postes:{
        Management:[
            {text:"Direction Général", value:"DG"},
            {text:"Logistique & Moyens Généraux", value:"LMG"},
            {text:"Contrats & Approvisionnement", value:"CA"},
            {text:"Finances & Comptabilité", value:"FC"},
            {text:"Hygiene Santé & Sécurité", value:"HSE"},
            {text:"Sécurité Interne Entreprise", value:"SI"},
            {text:"Autres", value:"A"},
          ],
        Administration:[
            {text:"Moyens Généraux", value:"MG"},
            {text:"Logistique", value:"LT"},
            {text:"Contrats & Approvisionnement", value:"C&A"},
            {text:"Finances & Comptabilité", value:"F&C"},
            {text:"Hygiene Santé & Sécurité", value:"HSE"},
            {text:"Sécurité Interne Entreprise", value:"SIE"},
            {text:"Autres", value:"A"},
        ],
        Supervision:[
            {text:"Hebergement", value:"H"},
            {text:"Restauration", value:"R"},
            {text:"Maintenances des Installations", value:"MI"},
            {text:"Entretien & Nettoyage", value:"E&N"},
            {text:"Espace Verts", value:"EV"},
            {text:"Autres", value:"A"},
        ],
        AutresStaff:[]
      },
      Staff:[],
      options:[
        {id:"01",name:"Menzou Ayoub"},
        {id:"02",name:"Chennouf Baha"},
        {id:"03",name:"Hamadouche Mohamed"},
      ],
    };
  },
  watch:{
    SelectedCategory:{
      handler(val){
        if(val == 'Mgt') this.DiffusedRadio = this.Postes.Management;
        if(val == 'Adt') this.DiffusedRadio = this.Postes.Administration;
        if(val == 'Sv') this.DiffusedRadio = this.Postes.Supervision;
        if(val == 'AS') this.DiffusedRadio = this.Postes.AutresStaff;
      }
    }
  },

  methods:{
    onComplete(){
      console.log('ON')
      // this.$refs.principal.nextTab();
      this.$http.post('/base/lifebases/store',this.info)
      .then(response => {
        console.log(response)
        Swal.fire("Félicitations!", response.data.name, "success");
        this.$refs.principal.nextTab()
      })
      .catch(error => Swal.fire("Erreur!", error, "error"))
    }
  }
    
}
</script>
<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <form-wizard shape="tab" ref="principal" color="#556ee6">
          <tab-content icon="mdi mdi-information-variant" ref="wizard" title="Informations Générales">
              <div class="card">
                <div class="card-body">
                  <!-- <h4 class="card-title">New </h4> -->
                  <form-wizard color="#556ee6" @on-complete="onComplete">
                    <tab-content icon="mdi mdi-information-variant" title="Informations Générales">
                      <div class="row">
                            <div class="col-lg-3">
                              <div class="form-group">
                                <label for="formrow-inputCity"
                                  >Nom de la Base *</label
                                >
                                <input type="text" class="form-control" required v-model="info.name" placeholder="Life Base IRARA"/>
                              </div>
                            </div>

                            <div class="col-lg-3">
                              <div class="form-group">
                                <label for="formrow-inputCity">Email *</label>
                                <input type="email"  class="form-control" required v-model="info.email" placeholder="Life_Base_IRARA@sonatrach.dz"/>
                              </div>
                            </div>

                            <div class="col-lg-3">
                              <div class="form-group">
                                <label for="formrow-password-input">N° Telephone</label>
                                <input type="text"  class="form-control" v-model="info.phone" placeholder="030557412"/>
                              </div>
                            </div>
                            <div class="col-lg-3">
                              <label for="">Wilaya *</label>
                              <select class="form-control" required v-model="info.state">
                                <option>Selectionner la Wilaya</option>
                              </select>
                            </div>
                        </div>
                        <!-- <div class="row">
                          <div class="col-lg-6">
                            <label for=""></label>
                            <select class="form-control"></select>
                          </div>
                          <div class="col-lg-6"></div>
                        </div> -->
                        <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="formrow-inputCity">Adresse *</label>
                                <textarea
                                  v-model="info.address"
                                  class="form-control"
                                  cols="30"
                                  rows="1" 
                                  required
                                ></textarea>
                              </div>
                            </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-3">
                            <label for="">Nombre de Bureaux</label>
                            <input type="number" class="form-control" v-model="info.offices" placeholder="300">
                          </div>
                          <div class="col-lg-3">
                            <label for="">Nombre de Chambres</label>
                            <input type="number" class="form-control" v-model="info.rooms" placeholder="500">
                          </div>
                          <div class="col-lg-3">
                            <label for="">Nombre de Cuisine</label>
                            <input type="number" class="form-control" v-model="info.kitchens" placeholder="50">
                          </div>
                          <div class="col-lg-3">
                            <label for="">Nombre de Restaurants</label>
                            <input type="number" class="form-control" v-model="info.restaurants" placeholder="30">
                          </div>
                        </div>

                      <!-- end row -->
                    </tab-content>
                    <tab-content icon="mdi mdi-checkbox-marked-circle-outline">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="card">
                            <h4 class="card-title pl-2 mb-4">Informations Générales</h4>
                            <table class="table table-nowarp mb-0">
                              <tbody>
                                <tr>
                                  <th scope="row">Nom de la Base</th>
                                  <td> {{info.name}} </td>
                                </tr>
                                <tr>
                                  <th scope="row">Email</th>
                                  <td>{{info.email}}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Telephone</th>
                                  <td>{{info.phone}}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Wilaya</th>
                                  <td>{{info.state}}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Addresse</th>
                                  <td>{{info.address}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="card">
                            <h4 class="card-title pl-2 mb-4">Informations Générales</h4>
                            <table class="table table-nowarp mb-0">
                              <tbody>
                                <tr>
                                  <th scope="row">Nombre de Chambres</th>
                                  <td> {{info.rooms}} </td>
                                </tr>
                                <tr>
                                  <th scope="row">Nombre de Bureaux</th>
                                  <td>{{info.offices}}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Nombre de Restaurants</th>
                                  <td>{{info.restaurants}}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Nombre de Cuisines</th>
                                  <td>{{info.kitchens}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <!-- end col -->
                      </div>
                      <!-- end row -->
                    </tab-content>
                  </form-wizard>
                </div>
                <!-- end card-body -->
              </div>
          </tab-content>
          <tab-content icon="mdi mdi-office-building" title="Infrastructure">
              <infrastructure/>
          </tab-content>
          <tab-content icon="mdi mdi-account-network" title="Info Staff">
                      <div class="card">
                        <div class="card-body">
                          <div class="row ml-4">
                            <div class="col-lg-4">
                              <div class="row" v-for="Category in Categories" :key="Category.value">
                                <input type="radio" class="form-check-input" :value="Category.value" name="PosteCategories" v-model="SelectedCategory">
                                <label class="form-check-label" for="PosteCategories" > {{Category.text}} </label>
                              </div>
                            </div>
                            <div class="col-lg-4" v-if="DiffusedRadio">
                              <div class="row" v-for="Poste in DiffusedRadio" :key="Poste.value">
                                <input type="radio" class="form-check-input" :value="Poste.value"   name="Postes" v-model="SelectedPoste" >
                                <label class="form-check-label" for="Postes" > {{Poste.text}} </label>
                              </div>
                            </div>
                            <div class="col-lg-4" v-if="SelectedPoste">
                              <multiselect :options="options" placeholder="Pick action" :multiple="true" label="name"></multiselect>
                            </div>
                          </div>
                        </div>
                      </div>
                      
          </tab-content>
        </form-wizard>
        
      </div>
    </div>
    <!-- </Layout> -->
</template>